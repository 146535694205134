import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import UpdatePassword from './pages/Login/UpdatePassword';
import EmailVerify from './pages/Signup/EmailVerify';
import MedicalScribe from './pages/MedicalScribe/MedicalScribe';
import ConsultationSummary from './pages/MedicalScribe/MedicalScribeSummary';
import { useSelector, shallowEqual } from 'react-redux';
import CreateProfile from './pages/Signup/CreateProfile';
import SettingsPage from './pages/Settings/Settings';
import ScreenRecorder from './components/ScreenRecorder';
import Chats from './pages/Chats/Chats';
import ScreenRecordergcloud from './components/ScreenRecordergcloud';

const App = () => {
  const { isLoggedIn } = useSelector((state) => ({ isLoggedIn: state.globalState.isLoggedIn }), shallowEqual);
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/consultation" element={isLoggedIn ? <MedicalScribe /> : <Navigate to="/login" />} />
      <Route path="/consultationSummary" element={isLoggedIn ? <ConsultationSummary /> : <Navigate to="/login" />} />
      {/* <Route path="/chats" element={isLoggedIn ? <Chats /> : <Navigate to="/login" />} /> */}
      <Route path="/emailVerify" element={!isLoggedIn ? <EmailVerify /> : <Navigate to="/login" />} />
      <Route path="/updatePassword" element={!isLoggedIn ? <UpdatePassword /> : <Navigate to="/login" />} />
      <Route path="/createProfile" element={!isLoggedIn ? <CreateProfile /> : <Navigate to="/login" />} />
      <Route path="/settings" element={isLoggedIn ? <SettingsPage /> : <Navigate to="/login" />} />
      {/* <Route path="/screenRecorder" element={ <ScreenRecorder /> } /> */}
    </Routes>
  );
};

export default App;
