import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props) {
  const { open, content, setOpen, confirmAction} = props.data;

  const handleClose = () => {
    setOpen((prevState) => ({...prevState, open: false}));
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm the action"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{borderRadius: 30, backgroundColor: '#F8F9FA', padding: '5px 10px', color: "#29C0BB", cursor: 'pointer'}} onClick={handleClose}>Cancel</Button>
          <Button sx={{borderRadius: 30, backgroundColor: '#29C0BB', padding: '5px 10px', color: "#ffffff", cursor: 'pointer'}} onClick={() => {handleClose(); confirmAction();}} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
