// supabaseClient.js
import { createClient } from '@supabase/supabase-js';
import axios from 'axios';

const API_URL = 'https://us-central1-tikcare-d0cb9.cloudfunctions.net';

// Replace with your Supabase URL and anon key
const supabaseUrl = 'https://vzsqbjdvkovxjyrcudpw.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZ6c3FiamR2a292eGp5cmN1ZHB3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTA4NTEyMzIsImV4cCI6MjAyNjQyNzIzMn0.o33oenSJbazXH093J6igic0JEGBypHmd3BEO8VvDEEA';

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const TikcareAssistantService = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
    //   'Access-Control-Allow-Origin': '*',
    },
});