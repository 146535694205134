import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import MicIcon from '@mui/icons-material/Mic';
import PauseIcon from '@mui/icons-material/Pause';
import './Soundwave.css'; // Import the CSS for the animation

const AudioInputAnimation = (props) => {
  const [timer, setTimer] = useState(0); // Timer in seconds
  const [intervalId, setIntervalId] = useState(null); // Store interval ID

  // Format timer into 'MM:SS' format
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (props.isRecognizing) {
      setTimer(0);
      const id = setInterval(() => {
        setTimer((prevTime) => prevTime + 1);
      }, 1000);
      setIntervalId(id);
    } else {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [props.isRecognizing]);

  const handleMicClick = () => {
    props.sttFromMic();
  };

  return (
    <div className="audio-input-animation">
      <div onClick={handleMicClick}>
        <Tooltip title={props.isRecognizing ? "Stop Recording" : "Start Recording"}>
          <IconButton>
              {props.isRecognizing ? <PauseIcon /> : <MicIcon />}
          </IconButton>
        </Tooltip>
      </div>

      <div className={`soundwave ${props.isRecognizing ? 'listening' : 'not-listening'}`}>
        {[...Array(15)].map((_, index) => (
          <div key={index} className="wave"></div>
        ))}
      </div>

      <div className="timer">
        {formatTime(timer)}
      </div>
    </div>
  );
};

export default AudioInputAnimation;
