import React, { useState, useEffect } from 'react';
import { Container, IconButton, Typography, MenuItem, Select, FormControl, InputLabel, Box, TextField, Tooltip } from '@mui/material';
import { useMedicalScribeData } from './MedicalScribeData';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import SearchBar from '../../components/Search.js';
import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css'; 
import { Link } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PopupMenu from './PopupMenu/PopupMenu';
import Loader from '../../loader.js';
import '../../Calendar.css'; 
import { styled } from '@mui/material/styles';
import MiniDrawer from '../../components/MiniDrawer.js';
import PrimarySearchAppBar from '../../components/Appbar.js';
import { useTheme } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useAlert from '../../alert/useAlert.js';
import AlertDialog from '../../Dialog.js';

const MedicalScribe = () => {
  const { patientList, consultationList, newPatient, setNewPatient, createPatient, updatePatient, deletePatient, userDetails } = useMedicalScribeData();
  const options = [{ value: 'Patients', label: 'Patients' },
    { value: 'Consultations List', label: 'Consultations List' }
  ];
  const [view, setView] = useState('Patients');
  const [sortOrder, setSortOrder] = useState('Newest');
  const [date, setDate] = useState(new Date()); 
  const [searchText, setSearchText] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [sortedConsultations, setSortedConsultations] = useState([])
  const [sortedPatients, setSortedPatients] = useState([])
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [countryCode, setCountryCode] = useState('+852');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [complaint, setComplaint] = useState('');
  const [remarks, setRemarks] = useState('');
  const countryCodes = ['+1', '+44', '+91', '+852']; 
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isPopupPatient, setPopupPatient] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState('');
  const [openDialog, setOpenDialog] = useState({ open: false, content: '', confirmAction: null });
  const { setAlert } = useAlert();
  const theme = useTheme();

  useEffect(() => {
    const sortedConsultations = [...consultationList].filter(consultation => new Date(new Date(consultation.createdAt).toUTCString().split(' ').slice(0, 4).join(' ')).toDateString() === date.toDateString()).sort((a, b) => {
      if (sortOrder === 'Newest') {
        return new Date(b.createdAt) - new Date(a.createdAt);
      } else {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
    });
    const sortedPatients = [...patientList].sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    setSortedConsultations(sortedConsultations);
    setSortedPatients(sortedPatients);
    setFilteredItems(view === 'Consultations List' ? sortedConsultations : sortedPatients);
    setSelectedPatient('');
  }, [sortOrder, consultationList, patientList, date]);

  const handleSortChange = (event) => {
    setSearchText('');
    setSortOrder(event.target.value);
  };

  const handleViewChange = (value) => {
    setSearchText('');
    if(view !== value){
      setFilteredItems(view === 'Consultations List' ? sortedPatients : sortedConsultations);
      setView(value);
      setDate(new Date());
    }
    setTimeout(() => setLoading(false), 500);
  };

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchText(searchValue);
    const filtered = view === 'Consultations List' ? sortedConsultations.filter((consultation) =>
      patientList.find((patient) => patient.id === consultation.medicalScribeId)?.name.toLowerCase().includes(searchValue))
     : 
     sortedPatients.filter((patient) =>
      patient.name.toLowerCase().includes(searchValue));
    setFilteredItems(filtered);
  };

  const handlePatientEdit = (e, id) => {
    e.preventDefault();
    setFirstName(patientList.find((patient) => patient.id === id)?.firstName);
    setLastName(patientList.find((patient) => patient.id === id)?.lastName);
    setCountryCode(patientList.find((patient) => patient.id === id)?.phoneNumber.split(' ')[0]);
    setPhoneNumber(patientList.find((patient) => patient.id === id)?.phoneNumber.split(' ')[1]);
    setSelectedPatient(id);
    setFormSubmitted(false); 
    setPopupPatient(true);
  };

  return (
    <Container disableGutters maxWidth={false}>
      <Grid container sx={{overflow: 'auto', minHeight: '100vh', minWidth: '100vw'}}>
        <Grid size={{xs: 1, md: 0}}>
          <MiniDrawer />
        </Grid>
        <Grid size="grow">
          <Grid container spacing={5} sx={{ backgroundColor: '#F8F9FA', display: 'flex', flexDirection: 'column',  width: '100%', height: '100%' }}>
            <Grid size={12}>
              <PrimarySearchAppBar/>
            </Grid>
            <Grid size={12}>
              <div style={{ padding: '0px 10vw', marginTop: theme.mixins.toolbar.minHeight}}>
                <Grid container spacing={2} sx={{ flexDirection: { xs: "column", md: "row"}   }} justifyContent="space-between">
                  <Grid size={{xs: 12, md: 8 }}>
                    <Grid container direction="column">
                      <Grid sx={{marginBottom: '10px'}}>
                        <Typography variant="h4" component="div" >
                          {`Welcome to ${userDetails.name ?? 'unknown'}'s space`}
                        </Typography>
                      </Grid>
                      <Grid size="auto" sx={{marginBottom: '20px'}}>
                        {options.map((option) => (
                            <Button
                            key={option.value}
                            onClick={() => { setLoading(true); handleViewChange(option.value); }}
                            sx={{
                                flex: 1,
                                textTransform: 'none',
                                borderBottom: view === option.value ? '3px solid #29C0BB' : 'none',
                                paddingRight: '20px',
                                paddingTop: '8px',
                                color: '#000000',
                                fontWeight: 'bold',
                            }}
                            >
                            {option.label}
                            </Button>
                        ))}
                      </Grid>
                      {
                        loading ? 
                        <Grid container spacing={2} sx={{ padding: '50px', height: '50vh' }} justifyContent="center" alignItems="center">
                          <Loader /> 
                        </Grid>
                        :
                        view === 'Consultations List' ? (
                          <Grid size="grow">
                            <Grid container sx={{ marginBottom: '16px'}}>
                              {/* <Grid size="auto">
                                <Button variant="outlined" startIcon={<AddIcon />} sx={{marginRight: '20px', borderRadius: 30, backgroundColor: '#29C0BB', border: 0}}>
                                  <Typography variant="button" sx={{color: '#FFFFFF'}}>New Consultation</Typography>
                                </Button>
                              </Grid> */}
                              <Grid size="auto">
                                <SearchBar bgcolor='#FFFFFF' placeholder="Search for a consultation" width="30vw" value={searchText} onChange={handleSearchChange}/>
                              </Grid>
                            </Grid>
              
                            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                              <Grid size="auto">
                                <Typography variant="h6" color="textSecondary">
                                  {date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                                </Typography>
                              </Grid>
                              <Grid size="auto"> 
                                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                                  <InputLabel>Sort by</InputLabel>
                                  <Select
                                    value={sortOrder}
                                    onChange={handleSortChange}
                                    label="Sort by"
                                  >
                                    <MenuItem value="Newest">Newest</MenuItem>
                                    <MenuItem value="Oldest">Oldest</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
              
                            <Grid container spacing={2} direction="column">
                              {filteredItems.map((consultation) => (
                                <Grid size={12} key={consultation.id}>
                                  <Link 
                                    to={{
                                      pathname: '/consultationSummary',
                                    }} 
                                    state={{
                                      params: {
                                        id: patientList.find((patient) => patient.id === consultation.medicalScribeId)?.id, 
                                        name: `${patientList.find((patient) => patient.id === consultation.medicalScribeId)?.lastName?.toUpperCase() ?? ""} ${patientList.find((patient) => patient.id === consultation.medicalScribeId)?.firstName ?? ""}`,  
                                      }
                                    }}
                                    style={{ textDecoration: 'none' }}
                                  >
                                    <Grid container sx={{ '&:hover': { border: "3px solid #29C0BB" }, backgroundColor: '#FFFFFF', borderRadius: '10px', padding: '16px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                      <Grid size={"grow"}>
                                        <Typography variant="body1" sx={{fontWeight: 'bold', fontSize: '18px'}}>
                                          {`${patientList.find((patient) => patient.id === consultation.medicalScribeId)?.lastName?.toUpperCase() ?? ""} 
                                            ${patientList.find((patient) => patient.id === consultation.medicalScribeId)?.firstName ?? ""} 
                                            ${consultation.chiefComplaint !== "Not Available" ? `(${consultation.chiefComplaint})` : ""}`
                                          }</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                          {`${new Date(consultation.createdAt.split('T')[0]).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })} 
                                            ${consultation.createdAt.split('T')[1].split(':').slice(0, 2).join(':')}`
                                          }
                                        </Typography>
                                        {/* <Typography variant="body1">{consultation.notes}</Typography> */}
                                      </Grid>
                                      <Grid size={"auto"} sx={{display: 'flex', alignItems: 'center'}}>
                                        {/* <Typography variant="h6" align="right">{consultation.caseType ?? "New Case"}</Typography> */}
                                        <Typography variant="body2" color="textSecondary" align="right">
                                          {patientList.find((patient) => patient.id === consultation.medicalScribeId)?.phoneNumber.split(' ')[1] ? patientList.find((patient) => patient.id === consultation.medicalScribeId)?.phoneNumber : "Phone number unknown"}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Link>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>)
                          :
                          (
                          <Grid size="grow">
                            <Grid container sx={{ marginBottom: '16px' }}>
                              <Grid size="auto">
                                <Button onClick={() => {setFormSubmitted(false); setFirstName(''); setLastName(''); setCountryCode('+852'); setPhoneNumber(''); setComplaint(''); setRemarks(''); setNewPatient(true)}} variant="outlined" startIcon={<AddIcon sx={{color: '#fff'}}/>} sx={{marginRight: '20px', borderRadius: 30, backgroundColor: '#29C0BB', border: 0}}>
                                  <Typography variant="button" sx={{color: '#FFFFFF'}}>New Patient</Typography>
                                </Button>
                              </Grid>
                              <Grid size="grow">
                              <SearchBar bgcolor='#FFFFFF' placeholder="Search for a patient" width="30vw" value={searchText} onChange={handleSearchChange}/>
                              </Grid>
                            </Grid>
              
                            <Grid container spacing={2} direction="column"> 
                              {filteredItems.map((patient) => (
                                <Grid size={12} key={patient.id}>
                                  <Link 
                                    to={{
                                      pathname: '/consultationSummary',
                                    }} 
                                    state={{
                                      params: {
                                        id: patient.id,
                                        name: `${patient.lastName?.toUpperCase() ?? ""} ${patient.firstName ?? ""}`,
                                      }
                                    }}
                                    style={{ textDecoration: 'none' }}
                                  >
                                    <Grid container sx={{ '&:hover': { border: "3px solid #29C0BB" }, backgroundColor: '#FFFFFF', borderRadius: '10px', padding: '16px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                      <Grid size={{xs: 3, md: 1.5}}>
                                        <IconButton
                                          size="large"
                                          edge="end"
                                          onClick={() => {}}
                                          >
                                          <AccountCircle sx={{ fontSize: '40px', marginRight: '10px' }}/>
                                        </IconButton>
                                      </Grid>
                                      <Grid size={{xs: 9, md: 6.5}}>
                                        <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                                          <Typography variant="body1" sx={{fontWeight: 'bold', fontSize: '18px'}}>{`${patient.lastName?.toUpperCase() ?? ""} ${patient.firstName ?? ""}`}</Typography>
                                          <Tooltip title="Edit Patient Details">
                                            <IconButton onClick={(e) => {handlePatientEdit(e, patient.id) }} >
                                              <EditIcon/>
                                            </IconButton> 
                                          </Tooltip>
                                          <Tooltip title="Delete Patient">
                                            <IconButton onClick={(e) => { e.preventDefault(); setOpenDialog({open: true, content: "Are you sure you want to delete this patient?", confirmAction: () => deletePatient(patient.id)}); }}>
                                              <DeleteIcon />
                                            </IconButton> 
                                          </Tooltip>
                                        </Stack>
                                        <Typography variant="body2" color="textSecondary">
                                          {`${consultationList.filter(consultation => consultation.medicalScribeId === patient.id).length || 0} Consultations`}
                                        </Typography>
                                      </Grid>
                                      <Grid size={{xs: 12, md: 4}} display="flex" direction="row" justifyContent="flex-end" alignItems="center">
                                        {/* <Typography variant="h6" align="right">{patient.email?? "Email unknown"}</Typography> */}
                                        <Typography variant="body2" color="textSecondary" align="right">
                                          {patient.phoneNumber.split(' ')[1]? patient.phoneNumber : "Phone number unknown"}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Link>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>                  
                          )}
                    </Grid>
                  </Grid>
                  {
                    view === 'Consultations List' ?
                    (
                      <Grid size={{xs: 12, md: 4}} sx={{display: 'flex', justifyContent:{ xs: 'flex-start', md: 'center' }, alignItems: 'center',}}>
                        <Calendar 
                          onChange={setDate} 
                          value={date}
                          style={{borderRadius: '20px',boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'}}
                        />
                      </Grid>
                    ) : <></>
                  }
                  {
                    newPatient && (
                      <PopupMenu>
                        <Typography variant="h6">Create Patient</Typography>
                          <Grid container spacing={2}>
                            <Grid size={6}>
                              <TextField
                                required
                                fullWidth
                                label="First Name"
                                variant="outlined"
                                focused
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="First Name" 
                                sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }} 
                                error={formSubmitted && !firstName}
                                helperText={formSubmitted && !firstName && "First Name is required"}
                              />
                            </Grid>
                            <Grid size={6}>
                              <TextField
                                required
                                fullWidth
                                label="Last Name"
                                variant="outlined"
                                focused
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Last Name" 
                                sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }}
                                error={formSubmitted && !lastName}
                                helperText={formSubmitted && !lastName && "Last Name is required"}
                              />
                            </Grid>
                            <Grid size={2}>
                              <TextField
                                select
                                fullWidth
                                variant="outlined"
                                value={countryCode}
                                onChange={(e) => setCountryCode(e.target.value)}
                                sx={{ backgroundColor: '#f5f5f5', marginBottom: '16px',  }}
                              >
                                {countryCodes.map((code) => (
                                  <MenuItem key={code} value={code}>
                                    {code}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid size={8}>
                              <TextField
                                fullWidth
                                label="Patient's Phone Number"
                                variant="outlined"
                                focused
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                placeholder="Patient's Phone Number" sx={{ backgroundColor: '#f5f5f5', marginBottom: '16px',  }}
                              />
                            </Grid>
                            {/* <Grid size={12}>
                              <TextField
                                fullWidth
                                label="Chief Complaint"
                                variant="outlined"
                                focused 
                                value={complaint}
                                onChange={(e) => setComplaint(e.target.value)}
                                placeholder="Eg. headache" sx={{ backgroundColor: '#f5f5f5', marginBottom: '16px',  }}
                              />
                            </Grid>
                            <Grid size={12}>
                              <TextField
                                fullWidth
                                label="Remarks"
                                variant="outlined"
                                focused
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                                placeholder="Type here to add comments" sx={{ backgroundColor: '#f5f5f5', marginBottom: '16px',  }}
                              />
                            </Grid> */}
                            <Grid size={12}>
                              <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>                   
                                <Box onClick={() => {setNewPatient(false)}} sx={{ width: '80px', textAlign: 'center', borderRadius: 30, backgroundColor: '#F8F9FA', padding: '5px 10px', color: "#29C0BB", cursor: 'pointer'}}>
                                  Close
                                </Box>  
                                <Box onClick={() => { 
                                    setFormSubmitted(true);
                                    if (!firstName || !lastName) {
                                      setAlert('Please fill in all required fields.', 'error');
                                      return;
                                    }
                                    createPatient(firstName, lastName, `${countryCode} ${phoneNumber}`, complaint, remarks, userDetails.id);   
                                  }} 
                                  sx={{ width: '80px', textAlign: 'center', borderRadius: 30, backgroundColor: '#29C0BB', padding: '5px 10px', color: "#ffffff", cursor: 'pointer'}}>
                                  Confirm
                                </Box>  
                              </Stack>
                            </Grid>
                          </Grid>
                      </PopupMenu> 
                    )
                  }
                  {
                    isPopupPatient && (
                      <PopupMenu>
                        <Typography variant="h6">Create Patient</Typography>
                          <Grid container spacing={2}>
                            <Grid size={6}>
                              <TextField
                                required
                                fullWidth
                                label="First Name"
                                variant="outlined"
                                focused
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="First Name" 
                                sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }} 
                                error={formSubmitted && !firstName}
                                helperText={formSubmitted && !firstName && "First Name is required"}
                              />
                            </Grid>
                            <Grid size={6}>
                              <TextField
                                required
                                fullWidth
                                label="Last Name"
                                variant="outlined"
                                focused
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Last Name" 
                                sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }}
                                error={formSubmitted && !lastName}
                                helperText={formSubmitted && !lastName && "Last Name is required"}
                              />
                            </Grid>
                            <Grid size={2}>
                              <TextField
                                select
                                fullWidth
                                variant="outlined"
                                value={countryCode}
                                onChange={(e) => setCountryCode(e.target.value)}
                                sx={{ backgroundColor: '#f5f5f5', marginBottom: '16px',  }}
                              >
                                {countryCodes.map((code) => (
                                  <MenuItem key={code} value={code}>
                                    {code}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid size={8}>
                              <TextField
                                fullWidth
                                label="Patient's Phone Number"
                                variant="outlined"
                                focused
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                placeholder="Patient's Phone Number" sx={{ backgroundColor: '#f5f5f5', marginBottom: '16px',  }}
                              />
                            </Grid>
                            <Grid size={12}>
                              <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>                   
                                <Box onClick={() => {setPopupPatient(false); }} sx={{ width: '80px', textAlign: 'center', borderRadius: 30, backgroundColor: '#F8F9FA', padding: '5px 10px', color: "#29C0BB", cursor: 'pointer'}}>
                                  Close
                                </Box>  
                                <Box onClick={() => { 
                                    setFormSubmitted(true);
                                    if (!firstName || !lastName) {
                                      setAlert('Please fill in all required fields.', 'error');
                                      return;
                                    }
                                    updatePatient(firstName, lastName, `${countryCode} ${phoneNumber}`, selectedPatient);
                                    setPopupPatient(false);    
                                  }} 
                                  sx={{ width: '80px', textAlign: 'center', borderRadius: 30, backgroundColor: '#29C0BB', padding: '5px 10px', color: "#ffffff", cursor: 'pointer'}}>
                                  Confirm
                                </Box>  
                              </Stack>
                            </Grid>
                          </Grid>
                      </PopupMenu>
                      )
                  }
                  {
                    openDialog && (
                      <AlertDialog data={{open: openDialog.open, content: openDialog.content, confirmAction: openDialog.confirmAction, setOpen: setOpenDialog}}/>
                    )
                  }
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MedicalScribe;
