import { Alert } from '@mui/material';
import useAlert from './useAlert.js';

const AlertPopup = () => {
  const { text, type } = useAlert();

  if (text && type) {
    return (
      <Alert
        variant="outlined"
        severity={type}
        sx={{
            position: 'fixed',  // or use 'fixed' if you want it to stay on the screen
            top: '10vh',                     // Vertical position
            left: '50vw',                 // Center horizontally
            transform: 'translateX(-50%)', // Adjust to center          // adjust this value for horizontal positioning
            zIndex: 10000,  
            bgcolor: 'background.paper'
        }}
      >
        {text}
      </Alert>
    );
  } else {
    return <></>;
  }
};

export default AlertPopup;