import React, { useState, useRef } from 'react';
import { Container, Button, Typography, Box, IconButton, MenuItem, Stack, TextField, FormControlLabel, Switch, Select as MuiSelect, FormControl, InputLabel } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Link, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import CopyIcon from '@mui/icons-material/FileCopy';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import AccountCircle from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import AddIcon from '@mui/icons-material/Add';
import AudioInputAnimation from './AudioInputAnimation/AudioInputAnimation';
import LanguageIcon from '@mui/icons-material/Language';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import PopupMenu from './PopupMenu/PopupMenu';
import { ReactComponent as Generate} from '../../assets/svg/generate.svg';
import { TikcareAssistantService } from '../../supabaseClient.js';
import Loader from '../../loader.js';
import { getTokenOrRefresh } from '../../token_util.js';
import { ResultReason } from 'microsoft-cognitiveservices-speech-sdk';
import { supabase } from '../../supabaseClient.js';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import MiniDrawer from '../../components/MiniDrawer.js';
import PrimarySearchAppBar from '../../components/Appbar.js';
import { useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Select from 'react-select';
import CloseIcon from '@mui/icons-material/Close';
import useAlert from '../../alert/useAlert.js';
import AlertDialog from '../../Dialog.js';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Assessment } from '@mui/icons-material';
import { useSelector, shallowEqual } from 'react-redux';
import { current } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { setDrawerOpen } from '../../store.js';

const speechsdk = require('microsoft-cognitiveservices-speech-sdk');

const ConsultationSummary = () => {
    const languages = 
    [
      { value: 'af-ZA', label: 'Afrikaans (South Africa)' },     
      { value: 'am-ET', label: 'Amharic (Ethiopia)' },
      { value: 'ar-AE', label: 'Arabic (United Arab Emirates)' },
      { value: 'ar-BH', label: 'Arabic (Bahrain)' },
      { value: 'ar-DZ', label: 'Arabic (Algeria)' },
      { value: 'ar-EG', label: 'Arabic (Egypt)' },
      { value: 'ar-IL', label: 'Arabic (Israel)' },
      { value: 'ar-IQ', label: 'Arabic (Iraq)' },
      { value: 'ar-JO', label: 'Arabic (Jordan)' },
      { value: 'ar-KW', label: 'Arabic (Kuwait)' },
      { value: 'ar-LB', label: 'Arabic (Lebanon)' },
      { value: 'ar-LY', label: 'Arabic (Libya)' },
      { value: 'ar-MA', label: 'Arabic (Morocco)' },
      { value: 'ar-OM', label: 'Arabic (Oman)' },
      { value: 'ar-PS', label: 'Arabic (Palestinian Authority)' },
      { value: 'ar-QA', label: 'Arabic (Qatar)' },
      { value: 'ar-SA', label: 'Arabic (Saudi Arabia)' },
      { value: 'ar-SY', label: 'Arabic (Syria)' },
      { value: 'ar-TN', label: 'Arabic (Tunisia)' },
      { value: 'ar-YE', label: 'Arabic (Yemen)' },
      { value: 'az-AZ', label: 'Azerbaijani (Latin, Azerbaijan)' },
      { value: 'bg-BG', label: 'Bulgarian (Bulgaria)' },
      { value: 'bn-IN', label: 'Bengali (India)' },
      { value: 'bs-BA', label: 'Bosnian (Bosnia and Herzegovina)' },
      { value: 'ca-ES', label: 'Catalan' },
      { value: 'cs-CZ', label: 'Czech (Czechia)' },
      { value: 'cy-GB', label: 'Welsh (United Kingdom)' },
      { value: 'da-DK', label: 'Danish (Denmark)' },
      { value: 'de-AT', label: 'German (Austria)' },
      { value: 'de-CH', label: 'German (Switzerland)' },
      { value: 'de-DE', label: 'German (Germany)' },
      { value: 'el-GR', label: 'Greek (Greece)' },
      { value: 'en-AU', label: 'English (Australia)' },
      { value: 'en-CA', label: 'English (Canada)' },
      { value: 'en-GB', label: 'English (United Kingdom)' },
      { value: 'en-GH', label: 'English (Ghana)' },
      { value: 'en-HK', label: 'English (Hong Kong SAR)' },
      { value: 'en-IE', label: 'English (Ireland)' },
      { value: 'en-IN', label: 'English (India)' },
      { value: 'en-KE', label: 'English (Kenya)' },
      { value: 'en-NG', label: 'English (Nigeria)' },
      { value: 'en-NZ', label: 'English (New Zealand)' },
      { value: 'en-PH', label: 'English (Philippines)' },
      { value: 'en-SG', label: 'English (Singapore)' },
      { value: 'en-TZ', label: 'English (Tanzania)' },
      { value: 'en-US', label: 'English (United States)' },
      { value: 'en-ZA', label: 'English (South Africa)' },
      { value: 'es-AR', label: 'Spanish (Argentina)' },
      { value: 'es-BO', label: 'Spanish (Bolivia)' },
      { value: 'es-CL', label: 'Spanish (Chile)' },
      { value: 'es-CO', label: 'Spanish (Colombia)' },
      { value: 'es-CR', label: 'Spanish (Costa Rica)' },
      { value: 'es-CU', label: 'Spanish (Cuba)' },
      { value: 'es-DO', label: 'Spanish (Dominican Republic)' },
      { value: 'es-EC', label: 'Spanish (Ecuador)' },
      { value: 'es-ES', label: 'Spanish (Spain)' },
      { value: 'es-GQ', label: 'Spanish (Equatorial Guinea)' },
      { value: 'es-GT', label: 'Spanish (Guatemala)' },
      { value: 'es-HN', label: 'Spanish (Honduras)' },
      { value: 'es-MX', label: 'Spanish (Mexico)' },
      { value: 'es-NI', label: 'Spanish (Nicaragua)' },
      { value: 'es-PA', label: 'Spanish (Panama)' },
      { value: 'es-PE', label: 'Spanish (Peru)' },
      { value: 'es-PR', label: 'Spanish (Puerto Rico)' },
      { value: 'es-PY', label: 'Spanish (Paraguay)' },
      { value: 'es-SV', label: 'Spanish (El Salvador)' },
      { value: 'es-US', label: 'Spanish (United States)' },
      { value: 'es-UY', label: 'Spanish (Uruguay)' },
      { value: 'es-VE', label: 'Spanish (Venezuela)' },
      { value: 'et-EE', label: 'Estonian (Estonia)' },
      { value: 'eu-ES', label: 'Basque' },
      { value: 'fa-IR', label: 'Persian (Iran)' },
      { value: 'fi-FI', label: 'Finnish (Finland)' },
      { value: 'fil-PH', label: 'Filipino (Philippines)' },
      { value: 'fr-BE', label: 'French (Belgium)' },
      { value: 'fr-CA', label: 'French (Canada)' },
      { value: 'is-IS', label: 'Icelandic (Iceland)' },
      { value: 'it-CH', label: 'Italian (Switzerland)' },
      { value: 'it-IT', label: 'Italian (Italy)' },
      { value: 'ja-JP', label: 'Japanese (Japan)' },
      { value: 'jv-ID', label: 'Javanese (Latin, Indonesia)' },
      { value: 'ka-GE', label: 'Georgian (Georgia)' },
      { value: 'kk-KZ', label: 'Kazakh (Kazakhstan)' },
      { value: 'km-KH', label: 'Khmer (Cambodia)' },
      { value: 'kn-IN', label: 'Kannada (India)' },
      { value: 'ko-KR', label: 'Korean (Korea)' },
      { value: 'lo-LA', label: 'Lao (Laos)' },
      { value: 'lt-LT', label: 'Lithuanian (Lithuania)' },
      { value: 'lv-LV', label: 'Latvian (Latvia)' },
      { value: 'mk-MK', label: 'Macedonian (North Macedonia)' },
      { value: 'ml-IN', label: 'Malayalam (India)' },
      { value: 'mn-MN', label: 'Mongolian (Mongolia)' },        
      { value: 'mr-IN', label: 'Marathi (India)' },
      { value: 'ms-MY', label: 'Malay (Malaysia)' },
      { value: 'mt-MT', label: 'Maltese (Malta)' },
      { value: 'my-MM', label: 'Burmese (Myanmar)' },
      { value: 'nb-NO', label: 'Norwegian Bokmål (Norway)' },   
      { value: 'ne-NP', label: 'Nepali (Nepal)' },
      { value: 'nl-BE', label: 'Dutch (Belgium)' },
      { value: 'nl-NL', label: 'Dutch (Netherlands)' },
      { value: 'pa-IN', label: 'Punjabi (India)' },
      { value: 'pl-PL', label: 'Polish (Poland)' },
      { value: 'ps-AF', label: 'Pashto (Afghanistan)' },        
      { value: 'pt-BR', label: 'Portuguese (Brazil)' },
      { value: 'pt-PT', label: 'Portuguese (Portugal)' },
      { value: 'ro-RO', label: 'Romanian (Romania)' },
      { value: 'ru-RU', label: 'Russian (Russia)' },
      { value: 'si-LK', label: 'Sinhala (Sri Lanka)' },
      { value: 'sk-SK', label: 'Slovak (Slovakia)' },
      { value: 'sl-SI', label: 'Slovenian (Slovenia)' },
      { value: 'so-SO', label: 'Somali (Somalia)' },
      { value: 'sq-AL', label: 'Albanian (Albania)' },
      { value: 'sr-RS', label: 'Serbian (Cyrillic, Serbia)' },
      { value: 'sv-SE', label: 'Swedish (Sweden)' },
      { value: 'sw-KE', label: 'Kiswahili (Kenya)' },
      { value: 'sw-TZ', label: 'Kiswahili (Tanzania)' },
      { value: 'ta-IN', label: 'Tamil (India)' },
      { value: 'te-IN', label: 'Telugu (India)' },
      { value: 'th-TH', label: 'Thai (Thailand)' },
      { value: 'tr-TR', label: 'Turkish (Türkiye)' },
      { value: 'uk-UA', label: 'Ukrainian (Ukraine)' },
      { value: 'ur-IN', label: 'Urdu (India)' },
      { value: 'uz-UZ', label: 'Uzbek (Latin, Uzbekistan)' },
      { value: 'vi-VN', label: 'Vietnamese (Vietnam)' },
      { value: 'wuu-CN', label: 'Chinese (Wu, Simplified)' },
      { value: 'yue-CN', label: 'Chinese (Cantonese, Simplified)' },
      { value: 'zh-CN', label: 'Chinese (Mandarin, Simplified)' },
      { value: 'zh-CN-shandong', label: 'Chinese (Jilu Mandarin, Simplified)'},
      { value: 'zh-CN-sichuan', label: 'Chinese (Southwestern Mandarin, Simplified)'},
      { value: 'zh-HK', label: 'Chinese (Cantonese, Traditional)' },
      { value: 'zh-TW', label: 'Chinese (Taiwanese Mandarin, Traditional)'},
      { value: 'zu-ZA', label: 'isiZulu (South Africa)' }
    ]
    const [selectedMicId, setSelectedMicId] = useState(null);
    const location = useLocation();
    const { state } = location;
    const { id, name } = state?.params || {};
  
    const [view, setView] = useState('Live Transcription');
    const [language, setLanguage] = useState({ value: 'zh-HK', label: 'Chinese (Cantonese, Traditional)' },);
    const options = [
        { value: 'Live Transcription', label: 'Live Transcription' },
        { value: 'Generated Note', label: 'Generated Note' },
    ];
    const [showPastConsultations, setShowPastConsultations] = useState(true);  
    const [isPopupSoap, setPopupSoap] = useState(false);
    const [isPopupSummary, setPopupSummary] = useState(false);
    const [isPopupReferral, setPopupReferral] = useState(false);
    const [isPopupCertificate, setPopupCertificate] = useState(false);
    const [consultationSummaryCheckbox, setConsultationSummaryCheckbox] = useState([]);
    const [consultationSummaryList, setConsultationSummaryList] = useState([]); 
    const [consultationSoap, setConsultationSoap] = useState([]);
    const [selectedConsultationSoap, setSelectedConsultationSoap] = useState({});
    const [selectedConsultationComplaint, setSelectedConsultationComplaint] = useState('');
    const [currentConsultation, setCurrentConsultation] = useState(null);
    const [selectedConsultation, setSelectedConsultation] = useState(null);
    const [editNote, setEditNote] = useState(false);
    const [editSoap, setEditSoap] = useState(false);
    const [editReferralContent, setEditReferralContent] = useState(false);
    const [editCertificateContent, setEditCertificateContent] = useState(false);
    const [subjective, setSubjective] = useState('');
    const [objective, setObjective] = useState('');
    const [assessment, setAssessment] = useState('');
    const [plan, setPlan] = useState('');
    const [transcription, setTranscription] = useState('');
    const [remarks, setRemarks] = useState('');
    const [loading, setLoading] = useState(false);
    const [savingConsultation, setSavingConsultation] = useState(false);
    const [generatingReferral, setGeneratingReferral] = useState(false);  
    const [generatingCertificate, setGeneratingCertificate] = useState(false);
    const [recognizer, setRecognizer] = useState(null);
    const [isRecognizing, setIsRecognizing] = useState(false);
    const throttleTimeout = useRef(null);
    const [firstView, setFirstView] = useState(true);
    const [updateConsultationList, setUpdateConsultationList] = useState(false);
    const textFieldRef = useRef(null);
    const transcriptionRef = useRef(transcription);
    const remarksRef = useRef(remarks);
    const [pastConsultations, setPastConsultations] = useState([]);
    const [currentSoap, setCurrentSoap] = useState(false);
    const [files, setFiles] = useState([]);
    const theme = useTheme();
    const { setAlert } = useAlert();
    const [openDialog, setOpenDialog] = useState({ open: false, content: '', confirmAction: null });
    const [inputSource, setInputSource] = useState(null); 
    const [inputSources, setInputSources] = useState([]);
    const [micStream, setMicStream] = useState(null);
    const [systemStream, setSystemStream] = useState(null);
    const [isTeleHealthActive, setIsTeleHealthActive] = useState(false);
    const hasMounted = useRef(false);
    const [generateOption, setGenerateOption] = useState('');
    // const [consultationSummaries, setConsultationSummaries] = useState({});
    const [referralLetterContent, setReferralLetterContent] = useState('');
    const [certificateContent, setCertificateContent] = useState('');

    const { userDetails } = useSelector((state) => ({userDetails: state.globalState.userDetails}), shallowEqual);
    const dispatch = useDispatch();

    const REACT_APP_SPEECH_KEY='7603d81833d04a229e7190f7cd0284e2'
    const REACT_APP_SPEECH_REGION='eastus'

    React.useEffect(() => {
      const fetchData = async () => {
        const consultations = await fetchConsultationData();
        if (consultations) {
          const soapData = consultations.map(consultation => {
            return parseSections(consultation.summary);
          });
          setConsultationSoap(soapData);
          setConsultationSummaryCheckbox(Array(consultations.length).fill(false));
          // generateConsultationSummaries(consultations);
        }
      };
      fetchData();
    }, [ updateConsultationList ]);

    React.useEffect(() => {
      if (textFieldRef.current) {
        textFieldRef.current.scrollTop = textFieldRef.current.scrollHeight;
      }
      transcriptionRef.current = transcription;
      remarksRef.current = remarks;
    }, [transcription, remarks]);

    React.useEffect(() => {
      if (hasMounted.current) { return; }
      getMicrophones();
      dispatch(setDrawerOpen(false))
      hasMounted.current = true;
    }, []);

    const fetchConsultationData = async () => {
      let { data, error } = await supabase.from('consultationmodel').select('*').eq('medicalScribeId', id);
      if (error){
        console.error(error);
          return;
        }
        const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setPastConsultations(sortedData);
        
        return sortedData;
      };

    function parseSections(summary) {
      const sectionMap = {};
    
      const regExp = /Subjective:\s*(.*?)\s*Objective:\s*(.*?)\s*Assessment:\s*(.*?)\s*Plan:\s*(.*)/s;
    
      const match = summary.match(regExp);
    
      if (match) {
        sectionMap['Subjective'] = match[1]?.trim() === 'null' ? '' : match[1].trim();
        sectionMap['Objective'] = match[2]?.trim() === 'null' ? '' : match[2].trim();
        sectionMap['Assessment'] = match[3]?.trim() === 'null' ? '' : match[3].trim();
        sectionMap['Plan'] = match[4]?.trim() === 'null' ? '' : match[4].trim();
      } else {
        console.log('No match found');
      }
    
      return sectionMap;
    }

    const handleCheckboxChange = (index) => {
      const updatedSummary = [...consultationSummaryCheckbox];
      updatedSummary[index] = !updatedSummary[index];
      setConsultationSummaryCheckbox(updatedSummary);
    };

    const handleViewConsultation = (index) => {
      setSelectedConsultation(pastConsultations[index]);
      setSelectedConsultationSoap(consultationSoap[index]);
      setSelectedConsultationComplaint(pastConsultations[index].chiefComplaint);
      setPopupSoap(true);
    };
  
    const handleViewChange = (value) => {
      setView(value);
    };

    const saveSoapNotes = async () => {
      const updatedSummary = `Subjective: \n${currentSoap ? subjective : selectedConsultationSoap.Subjective}\n\nObjective: \n${currentSoap ? objective : selectedConsultationSoap.Objective}\n\nAssessment: \n${currentSoap ? assessment : selectedConsultationSoap.Assessment}\n\nPlan: \n${currentSoap ? plan : selectedConsultationSoap.Plan}`
      let chiefComplaint = await medicalScribeGetChiefCompaint(updatedSummary);
      chiefComplaint = chiefComplaint.replace("Chief complaint: ", "").trim();
      chiefComplaint = chiefComplaint.endsWith('.') ? chiefComplaint.slice(0, -1) : chiefComplaint;
      const { data, error } = await supabase.from('consultationmodel').update({ summary: updatedSummary, chiefComplaint: currentSoap ? chiefComplaint !== "null" ? chiefComplaint : "Not Available" : selectedConsultationComplaint }).eq('id', currentSoap ? currentConsultation.id : selectedConsultation.id);
      if (error){
        console.error(error);
      }
      else {
        setSelectedConsultation(selectedConsultation => ({ ...selectedConsultation, chiefComplaint: chiefComplaint }));
        setUpdateConsultationList(!updateConsultationList);
        if(currentSoap){
          setCurrentSoap(false);
        }
        setAlert('Consultation notes updated successfully!', 'success');
      };
    }

    const handleCopy = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        if(isPopupReferral) {
          setAlert('Referral letter content copied to clipboard!', 'success');
        } else {
          setAlert('Consultation notes copied to clipboard!', 'success');
        }
      }).catch((err) => {
        console.error('Failed to copy: ', err);
      });
    };

    const getSelectedConsultationSummary = async () => {
      setLoading(true);
      for (let index = 0; index < consultationSummaryCheckbox.length; index++) {
        if (consultationSummaryCheckbox[index]) {
          const createdAt = pastConsultations[index]?.createdAt;
          if (createdAt) {
            const date = new Date(createdAt.split('T')[0]).toLocaleDateString('en-US', {day: '2-digit', month: 'short', year: 'numeric', });
            const time = createdAt.split('T')[1].split(':').slice(0, 2).join(':');
            const notes = pastConsultations[index].summary
              ? `${pastConsultations[index].summary}`
              : 'No SOAP summary available to display';
            await medicalScribeSummarizeAllConsultation(notes, date, time);
          }
        }
      }
      setLoading(false);
    };
    
    
    const medicalScribeSummarizeAllConsultation = async (notes, date, time) => {
      try {
        const response = await TikcareAssistantService.post('/medical-scribe-summarize-all-consultation', { notes });
        setConsultationSummaryList(prevState => [...prevState, {dateTime: `${date} ${time}`, data: response.data.body}]);
      } catch (error) {
        console.error('Error in medicalScribeSummarizeAllConsultation:', error);
        setConsultationSummaryList(prevState => [...prevState, {dateTime: `${date} ${time}`, data: 'No Summary available to display'}]);
      }
    };

    const medicalScribeGetChiefCompaint = async (notes) => {
      try {
        const response = await TikcareAssistantService.post('/medical-scribe-get-chief-complaint', { notes });
        return response.data.body;
      } catch (error) {
        console.error('Error in medicalScribeSummarizeAllConsultation:', error);
        return 'Not Available';
      }
    };

    const throttledFunction = (func, delay) => {
      if (throttleTimeout.current) {
        return;
      }
      throttleTimeout.current = setTimeout(() => {
        func();
        throttleTimeout.current = null;
      }, delay);
    };

    const medicalScribeSummarize = async () => {
      try {
        const notes = [transcriptionRef.current ?? "", remarksRef.current ?? ""];
        const response = await TikcareAssistantService.post('/medical-scribe-summarize', { notes });
        setSubjective(response.data.body.Subjective ?? '[TBC]');
        setObjective(response.data.body.Objective ?? '[TBC]');
        setAssessment(response.data.body.Assessment ?? '[TBC]');
        setPlan(response.data.body.Plan ?? '[TBC]');
        return response.data.body;
      } catch (error) {
        console.error('Error in medicalScribeSummarize:', error);
        setSubjective('[TBC]');
        setObjective('[TBC]');
        setAssessment('[TBC]');
        setPlan('[TBC]');
        return {Subjective: '[TBC]', Objective: '[TBC]', Assessment: '[TBC]', Plan: '[TBC]'};
      }
    };

    const saveConsultation = async () => {
      const data = await medicalScribeSummarize();
      const updatedSummary = `Subjective: \n${data.Subjective}\n\nObjective: \n${data.Objective}\n\nAssessment: \n${data.Assessment}\n\nPlan: \n${data.Plan}`;
      let chiefComplaint = await medicalScribeGetChiefCompaint(updatedSummary);
      chiefComplaint = chiefComplaint.replace("Chief complaint: ", "").trim();
      chiefComplaint = chiefComplaint.endsWith('.') ? chiefComplaint.slice(0, -1) : chiefComplaint;
      // console.log("the current consultation is: ", currentConsultation, "and the updated summary is: ", updatedSummary, "and the chief complaint is: ", chiefComplaint);
      const { error } = await supabase.from('consultationmodel').update({ summary: updatedSummary, chiefComplaint: chiefComplaint !== "null" ? chiefComplaint : "Not Available", notes: remarksRef.current, transcription: transcriptionRef.current }).eq('id', currentConsultation.id);
      if (error){
        console.error(error);
      }
      else {
        setSavingConsultation(false);
        setUpdateConsultationList(!updateConsultationList);
        setCurrentConsultation(currentConsultation => ({ ...currentConsultation, transcription: transcriptionRef.current ?? "", notes: remarksRef.current ?? "" }));
      };
    }
    
    const reGenerateConsultation = async () => {
      setLoading(true);
      await saveConsultation();
      setLoading(false);
    }

    async function sttFromMic() {
        if (isRecognizing && recognizer) {
          recognizer.stopTranscribingAsync(() => {
            recognizer.close();
            micStream.getTracks().forEach(track => track.stop());
            if(isTeleHealthActive) {
              systemStream.getTracks().forEach(track => track.stop());
            }
            setIsRecognizing(false);
            setSavingConsultation(true);
            setTimeout(() => {
              saveConsultation();
            }, 2000); 
          });
        } else {
            // const tokenObj = await getTokenOrRefresh();
            // const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
            if(selectedMicId === null) {
              setAlert('Please select a microphone!', 'error');
              return;
            }
            let systemStream;
            if(isTeleHealthActive) {
              systemStream = await captureSystemAudio();
              if(systemStream === null) {
                setAlert('Audio capture failed: Please grant your microphone access.', 'error');
                return;
              }
            }
            let micStream = await captureMicrophoneAudio();
            let audioStream = isTeleHealthActive && systemStream.active ? combineAudioStreams(systemStream, micStream) : micStream;

            const speechConfig = speechsdk.SpeechConfig.fromSubscription(REACT_APP_SPEECH_KEY, REACT_APP_SPEECH_REGION);
            speechConfig.speechRecognitionLanguage = language.value;

            const audioConfig = speechsdk.AudioConfig.fromStreamInput(audioStream);
            const newRecognizer = new speechsdk.ConversationTranscriber(speechConfig, audioConfig);
            
            setRecognizer(newRecognizer);
            setIsRecognizing(true);

            newRecognizer.startTranscribingAsync(() => {
              console.log('Continuous transcription started');
            }, (err) => {
              console.error('Failed to start continuous transcription:', err);
            });

            newRecognizer.transcribed = (s, e) => {
              console.log(`${e.result.speakerId}=${e.result.text}`);
              if(e.result.reason === ResultReason.RecognizedSpeech && e.result.speakerId !== 'Unknown') {
                setTranscription((prevTranscription) => 
                  prevTranscription 
                ? `${prevTranscription}\n${e.result.speakerId}=${e.result.text}`
                : `${e.result.speakerId}=${e.result.text}`
                );
                throttledFunction(() => {
                  medicalScribeSummarize();
                }, 1000); 
              }
            };
            
            newRecognizer.canceled = (s, e) => {
              console.error(`CANCELED: Reason=${e.reason}`);
              if (e.reason === speechsdk.CancellationReason.Error) {
                console.error(`CANCELED: ErrorCode=${e.errorCode}`);
                console.error(`CANCELED: ErrorDetails=${e.errorDetails}`);
              }
            };
        }
    }

    const insertConsultation = async () => {
      const now = new Date();
      const dateTime = new Date(now.getTime() + (8 * 60 * 60 * 1000));
      const { data, error } = await supabase.from('consultationmodel').insert([{ id: uuidv4(), medicalScribeId: pastConsultations.length ? pastConsultations[0].medicalScribeId : id, transcription: null, notes: null, summary: 'Subjective: null \n\n Objective: null \n\n Assessment: null \n\n Plan: null \n\n', createdAt: dateTime, chiefComplaint: "Not Available" }]).select('*');
      if (error) {
        console.error('Error upserting post:', error);
      }
      else {
        // pastConsultations.unshift(data[0]);
        setCurrentConsultation(data[0]);
        setUpdateConsultationList(!updateConsultationList);
        if(firstView){
          setFirstView(false);
        }
      }
    };

    const addNewConsultation = () => {
      insertConsultation();
      setFiles([]);
      setTranscription('');
      setRemarks('');
      setSubjective('');
      setObjective('');
      setAssessment('');
      setPlan('');
      setEditNote(false);
      transcriptionRef.current = '';
      remarksRef.current = '';
    }

    const viewPastConsultation = (index) => {
      setCurrentSoap(false);
      setEditNote(false);
      setFirstView(false);
      viewUploadedFiles(index);
      setCurrentConsultation(pastConsultations[index]);
      setSubjective(consultationSoap[index].Subjective);
      setObjective(consultationSoap[index].Objective);
      setAssessment(consultationSoap[index].Assessment);
      setPlan(consultationSoap[index].Plan);
      setTranscription(pastConsultations[index].transcription);
      setRemarks(pastConsultations[index].notes);
    }

    const removeConsultation = async () => {
      const { error } = await supabase.from('consultationmodel').delete().eq('id', selectedConsultation.id);
      if (error) {
        console.error('Error deleting post:', error);
      }
      else {
        setPopupSoap(false); 
        setEditSoap(false);
        if(!firstView){
          setFirstView(true);
        }
        setUpdateConsultationList(!updateConsultationList);
      }
    }

    const onInputClick = (event) => {
        event.target.value = ''
    }
  
    const handleFileChange = (event) => {
      const uploadedFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
      setEditNote(true);
      setCurrentSoap(true);
    };

    const removeFile = async (fileToRemove) => {
      const filePath = `${name} (${id})/${currentConsultation.id}/${fileToRemove.name}`;
    
      const { data: existingFiles, error: listError } = await supabase.storage.from('tikcare-attachments').list(`${name} (${id})/${currentConsultation.id}`);
    
      if (listError) {
        setAlert('Error checking file existence!', 'error');
        return;
      }
      const fileExists = existingFiles.some(file => file.name === fileToRemove.name);
      if (!fileExists) {
        setFiles(files.filter((file) => file !== fileToRemove));
        return;
      }
    
      const { error } = await supabase.storage.from('tikcare-attachments').remove([filePath]);
    
      if (error) {
        setAlert('Error removing file!', 'error');
      } else {
        setFiles(files.filter((file) => file !== fileToRemove));
        setAlert('File removed successfully!', 'success');
      }
    };
    

    const downloadFile = async (file) => {
      const { data, error } = await supabase.storage.from('tikcare-attachments').download(`${name} (${id})/${currentConsultation.id}/${file.name}`);
      if (error) {
        console.error('Error downloading file:', error);
      } else {
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name);
        document.body.appendChild(link);
        link.click();
      }
    }

    const handleSubmit = async () => {
      // Save uploaded files to Supabase
      for (const file of files) {
        const { data, error } = await supabase.storage.from('tikcare-attachments').upload(`${name} (${id})/${currentConsultation.id}/${file.name}`, file, {
          cacheControl: '3600',
          upsert: true
        })
        if (error) {
          console.error('Error uploading file:', error);
          setAlert('Error saving files! Please ensure file names are unique or valid.', 'error');
        } else {
          console.log('File uploaded successfully:');
        }
      }
    };

    const viewUploadedFiles = async (index) => {
      const { data, error } = await supabase.storage.from('tikcare-attachments').list(`${name} (${id})/${pastConsultations[index].id}`, {
        limit: 100,
        offset: 0,
        sortBy: { column: 'name', order: 'asc' },
      })
      if (error) {
        console.error('Error fetching files:', error);
      } else {
        setFiles(data);
      }
    }
  
    async function captureSystemAudio() {
      try {
        const systemStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: true,
          systemAudio: "include",
        });
        setSystemStream(systemStream);
        return new MediaStream(systemStream.getAudioTracks());
      } catch (error) {
        console.error("Error capturing system audio:", error);
        return null;
      }
    }
  
    async function captureMicrophoneAudio() {
      try {
        const micStream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: selectedMicId },
        });
        setMicStream(micStream);
        return micStream;
      } catch (error) {
        console.error("Error capturing microphone audio:", error);
        return null;
      }
    }
  
    function combineAudioStreams(systemStream, micStream) {
      const audioContext = new AudioContext();
      const destination = audioContext.createMediaStreamDestination();
      
      if (systemStream) {
        const systemSource = audioContext.createMediaStreamSource(systemStream);
        systemSource.connect(destination);
      }
    
      if (micStream) {
        const micSource = audioContext.createMediaStreamSource(micStream);
        micSource.connect(destination);
      }
    
      return destination.stream;  // This combined stream can be used for transcription
    }

    async function getMicrophones() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({audio: true});
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioDevices = devices.filter(device => (device.kind === 'audioinput' && device.deviceId !== 'default' && device.deviceId !== 'communications'));
        setInputSources([...audioDevices.map(device => ({ value: device.deviceId, label: device.label }))]);
        stream.getTracks().forEach(track => track.stop());
      } catch (error) {
        console.error('Error getting media devices:', error);
      }
    }

    // const generateConsultationSummaries = async (consultations) => {
    //   setConsultationSummaries(
    //     consultations.map(consultation => ({
    //       date: new Date(consultation.createdAt.split('T')[0]),
    //       consultation_note: `${consultation.summary} ${consultation.notes}`
    //     }))
    //   );
    // };

    const generateReferralLetter = async () => {
      try {
        const doctor_details = {
          doctor_name: userDetails.name,
          role: userDetails.practicetype,
          email: userDetails.email
        }
        const response = await TikcareAssistantService.post('/medical-scribe-summarize-referral', { consultation_note: `${currentConsultation.transcription}\nRemarks: ${currentConsultation.notes}`, patient_name: name, doctor_details});
        const cleanText = `${response.data.body.replace(/\*\*/g, '')}`;
        setReferralLetterContent(cleanText);
        await updateReferralLetterContent(cleanText);
        if(isPopupReferral){
          setGeneratingReferral(false);
          setAlert('Referral letter content regenerated successfully!', 'success');
        }
      } catch (error) {
        console.error('Error in generateReferralLetter:', error);
        return null;
      }
    }

    const generateMedicalCertificate = async () => {
      try {
        const current_date = new Date().toISOString().split('T')[0];

        const consultation = {
          date: new Date(currentConsultation.createdAt.split('T')[0]),
          consultation_note: `${currentConsultation.transcription}\nRemarks: ${currentConsultation.notes}`
        }
        const patient_details ={
          patient_name: name,
          case_no: currentConsultation.id
        }
        const doctor_details = {
          doctor_name: userDetails.name,
          role: userDetails.practicetype,
          email: userDetails.email
        }
        const response = await TikcareAssistantService.post('/medical-scribe-generate-certificate', { consultation, patient_details, doctor_details, current_date });
        const cleanText = `${response.data.body.replace(/\*\*/g, '')}`;
        setCertificateContent(cleanText);
        await updateCertificateContent(cleanText);
        if(isPopupCertificate){
          setGeneratingCertificate(false);
          setAlert('Medical Certificate content regenerated successfully!', 'success');
        }
      } catch (error) {
        console.error('Error in generateMedicalCertificate:', error);
        return null;
      }
    }

    const updateReferralLetterContent = async (data) => {
      const { error:  referralError} = await supabase.from('medicalscribemodel').update({ referralLetterContent: data }).eq('id', id);
      if(referralError){
        setAlert(referralError.message, 'error');
      }
      else{
        if(isPopupReferral){
          setReferralLetterContent(data);
          setAlert('Referral letter content updated successfully!', 'success');
        }
      }
    }

    const updateCertificateContent = async (data) => {
      const { error:  certificateError} = await supabase.from('consultationmodel').update({ certificate: data }).eq('id', currentConsultation.id);
      if(certificateError){
        setAlert(certificateError.message, 'error');
      }
      else{
        if(isPopupCertificate){
          setCertificateContent(data);
          setAlert('Medical Certificate has been updated successfully!', 'success');
        }
      }
    }

    const getReferralLetterContent = async () => {
      let { data, error } = await supabase.from('medicalscribemodel').select('*').eq('id',  id);
      if (error){
        console.error(error);
      }
      else{
        if(!data[0].referralLetterContent){
          await generateReferralLetter();
        } else {
          setReferralLetterContent(data[0].referralLetterContent);
        }
        setGeneratingReferral(false);
        setPopupReferral(true);
      }
    }

    const getCertificateContent = async () => {
      let { data, error } = await supabase.from('consultationmodel').select('*').eq('id', currentConsultation.id);
      if (error){
        console.error(error);
      }
      else{
        if(!data[0].certificate){
          await generateMedicalCertificate();
        } else {
          setCertificateContent(data[0].certificate);
        }
        setGeneratingCertificate(false);
        setPopupCertificate(true);
      }
    }

    return (
        <Container disableGutters maxWidth={false}>
          <Grid container sx={{overflow: 'auto', minHeight: '100vh', minWidth: '100vw'}}>
            <Grid size={{xs: 1, md: 0.5}}>
              <MiniDrawer />
            </Grid>
            <Grid size="grow">
              <Grid container spacing={5} sx={{ backgroundColor: '#F8F9FA', display: 'flex', flexDirection: 'column',  width: '100%', height: '100%' }}>
                <Grid size="auto">
                  <PrimarySearchAppBar/>
                </Grid>
                <Grid size={12}>
                <div style={{ marginTop: theme.mixins.toolbar.minHeight }}>
                  <Grid container spacing={2} sx={{ padding: '0px 50px' }}>
                      <Grid size={12} sx={{ marginBottom: '16px', display: 'flex', flexDirection: { xs: "column", md: "row" }, alignItems: 'center'}}>
                          <Tooltip title="Back to Consultation">
                            <IconButton component={Link} to="/consultation">
                                <ArrowBackIcon />
                                <Typography variant="h6" sx={{color: "#29C0BB", marginLeft: '5px'}}>Back</Typography>
                            </IconButton>
                          </Tooltip>
                          <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px'}}>
                            <IconButton
                                aria-label="account of current user"
                                aria-haspopup="true"
                                onClick={() => {}}
                                >
                                <AccountCircle sx={{ fontSize: '40px' }}/>
                            </IconButton>
                            <Typography variant="h6" sx={{fontWeight: 'bold'}}>{name}</Typography>
                          </div>
                          <Stack direction="row" alignItems="center" spacing={2}>
                          {/* <IconButton
                              size="large"
                              onClick={() => {}}
                              >
                              <InfoIcon/>
                          </IconButton> */}
                          {
                            !firstView &&
                            <Tooltip title={showPastConsultations ? "Hide Past Consultations" : "See Past Consultations" }>
                              <IconButton
                                size="large"
                                onClick={() => {setShowPastConsultations(!showPastConsultations)}}
                                >
                                  {
                                      showPastConsultations ? <OpenInFullIcon /> : <CloseFullscreenIcon />
                                  }
                              </IconButton>
                            </Tooltip>
                          }
                            <Button onClick={() => { addNewConsultation() }} variant="outlined" startIcon={<AddIcon sx={{color: '#ffffff'}}/>} sx={{borderRadius: 30, backgroundColor: '#29C0BB', border: 0}}>
                              <Typography noWrap variant="button" sx={{color: '#FFFFFF'}}>New Consultation</Typography>
                            </Button>
                          </Stack>
                    </Grid>
                    {
                      !firstView &&
                      <Grid size={showPastConsultations ? {xs: 12, md: 7} : 12}>
                        <Box sx={{
                          backgroundColor: '#FFFFFF',
                          padding: '5px 10px',
                          borderRadius: '8px',
                          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                          marginBottom: '16px',
                          display: 'flex',
                          flexDirection: { xs: "column", md: "row" },
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                          }}>
                        <Box>
                          <AudioInputAnimation isRecognizing={isRecognizing} setIsRecognizing={setIsRecognizing} sttFromMic={sttFromMic} />
                        </Box>
                        <Stack direction="column">
                          <Box sx={{ borderRadius: 30, backgroundColor: '#F8F9FA', marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                            <IconButton>
                                <LanguageIcon/>
                            </IconButton>
                            <Select
                              defaultValue={language}
                              onChange={setLanguage}
                              options={languages}
                              isDisabled={isRecognizing}
                              styles={{
                                control: base => ({
                                  ...base,
                                  border: 0,
                                  boxShadow: 'none',
                                  backgroundColor: '#F8F9FA',
                                  borderRadius: 30
                                })
                              }}
                            />
                          </Box>
                          <Box sx={{ borderRadius: 30, backgroundColor: '#F8F9FA', display: 'flex', alignItems: 'center'}}>
                            <IconButton>
                                <SettingsIcon />
                            </IconButton>
                            <Select
                            defaultValue={inputSource}
                            onChange={(e) => { setInputSource(e); setSelectedMicId(e.value) }}
                            options={inputSources}
                            placeholder="Input Source"
                            isDisabled={isRecognizing}
                            styles={{
                              control: base => ({
                                ...base,
                                border: 0,
                                boxShadow: 'none',
                                backgroundColor: '#F8F9FA',
                                borderRadius: 30
                              })
                            }}
                            />
                            <FormControlLabel 
                            control={                           
                              <Switch
                                checked={isTeleHealthActive}
                                onChange={(e) => { setIsTeleHealthActive(e.target.checked); }}
                                color="primary"
                                sx={{ marginLeft: '15px', marginRight: '0px' }}
                             />} 
                             label="TeleHealth" 
                             disabled={isRecognizing}
                            />
                          </Box>
                        </Stack>
                    </Box>
        
                    <Box
                    sx={{
                        display: 'flex',
                    }}
                    >
                    {options.map((option) => (
                        <Button
                        key={option.value}
                        onClick={() => { handleViewChange(option.value) }}
                        sx={{
                            flex: 1,
                            textTransform: 'none',
                            borderBottom: view === option.value ? '3px solid #29C0BB' : 'none',
                            padding: '8px 16px',
                            color: '#000000',
                            fontWeight: 'bold',
                        }}
                        >
                        {option.label}
                        </Button>
                    ))}
                    </Box>
        
                    {view === 'Live Transcription' ? (
                      <Box sx={{ backgroundColor: '#ffffff', padding: '16px', marginBottom: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Typography variant="h6" sx={{color: "#29C0BB", fontWeight: 'bold'}}>Live Transcription</Typography>
                        <TextField inputRef={textFieldRef} multiline maxRows={10} value={transcription ?? ''} disabled fullWidth placeholder="Start recording by pressing the start button..." sx={{ marginBottom: '25px', backgroundColor: '#f5f5f5' }} />
                        <Typography variant="h6" sx={{color: "#29C0BB", fontWeight: 'bold'}}>Manual Remarks</Typography>
                        <TextField sx={{marginBottom: '16px'}} multiline maxRows={10} value={remarks ?? ''} onChange={(e) => setRemarks(e.target.value)} fullWidth placeholder="You can enter any remarks here..." />
                      </Box>
                    ) : (
                    <Box sx={{ backgroundColor: '#ffffff', padding: '16px' }}>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="h6" sx={{color: "#29C0BB", fontWeight: 'bold'}}>Generated Note</Typography>
                          <Stack direction="row" spacing={1}>
                              <Tooltip title="Copy Note">
                                <IconButton onClick={() => { handleCopy(`Generated Note\n\nSubjective: \n${subjective}\n\nObjective: \n${objective}\n\nAssessment: \n${assessment}\n\nPlan: \n${plan}`) }}>
                                  <CopyIcon/>
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Upload Files">
                                <IconButton component="label" onClick={onInputClick}>
                                  <AttachFileIcon />
                                  <input type="file" multiple onChange={handleFileChange} style={{ display: 'none' }} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={editNote ? "Save Note" : 'Edit Note'}>
                                <IconButton onClick={() => { 
                                  if(editNote) {
                                    setEditNote(false);
                                    saveSoapNotes();
                                    handleSubmit();
                                  } else {
                                    setCurrentSoap(true);
                                    setEditNote(true);
                                  }}}>
                                  {editNote ? <SaveIcon/> : <EditIcon/>}
                                </IconButton>
                              </Tooltip>
                          </Stack>
                        </Stack>

                      {/* Display uploaded files */}
                        {files.map((file, index) => (
                          <Box key={index} sx={{ borderRadius: 3, backgroundColor: '#29C0BB', padding: '5px 10px', marginBottom: '16px', maxWidth: '100%', width: { xs: '100%', sm: 'fit-content', md: 'fit-content' },}}>
                            <Stack direction="row" key={index} alignItems="center">
                              <Typography noWrap variant="body2" sx={{ marginRight: '8px', color: '#fff' }}>{file.name}</Typography>
                              <IconButton onClick={() => removeFile(file)} sx={{color: '#fff'}}>
                                <CloseIcon />
                              </IconButton>
                              <IconButton onClick={() => {downloadFile(file)}} sx={{color: '#fff'}}>
                                <FileDownloadIcon />
                              </IconButton>
                            </Stack>
                          </Box>
                        ))}
                        <TextField multiline maxRows={10} label="Subjective" value={subjective ?? ''} onChange={(e) => setSubjective(e.target.value)} disabled={!editNote} fullWidth placeholder="Subjective..." sx={{ marginBottom: '16px', backgroundColor: '#f5f5f5' }} />
                        <TextField multiline maxRows={10} label="Objective" value={objective ?? ''} onChange={(e) => setObjective(e.target.value)} disabled={!editNote} fullWidth placeholder="Objective..." sx={{ marginBottom: '16px', backgroundColor: '#f5f5f5' }} />
                        <TextField multiline maxRows={10} label="Assessment" value={assessment ?? ''} onChange={(e) => setAssessment(e.target.value)} disabled={!editNote} fullWidth placeholder="Assessment..." sx={{ marginBottom: '16px', backgroundColor: '#f5f5f5' }} />
                        <TextField multiline maxRows={10} label="Plan" value={plan ?? ''} onChange={(e) => setPlan(e.target.value)} disabled={!editNote} fullWidth placeholder="Plan..." sx={{ backgroundColor: '#f5f5f5', marginBottom: '20px' }} />
                        <Stack direction="row" justifyContent="flex-end" alignItems="center">
                        <div style={{borderRadius: 10, backgroundColor: '#29C0BB', display: 'inline-block', alignItems: 'center', justifyContent: 'flex-end', padding: '0px 5px', '&:hover': { backgroundColor: '#f5f5f5'}, cursor: 'pointer'}}>
                        {/* <FormControl>
                        <InputLabel>Text</InputLabel> */}
                          <MuiSelect
                            labelId="generate-options"
                            value={generateOption}
                            onChange={(e) => setGenerateOption(e.target.value)}
                            label="Generate"
                            sx={{
                              borderRadius: 0,
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: 0
                              },
                              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: "none"
                              },
                            }}
                            displayEmpty
                            renderValue={(value) => {
                              // if (!value) {
                              //   return <Typography color='#fff'>Generate</Typography>;
                              // }
                              // return <Typography color='#fff'>{value}</Typography>;
                              return <Typography color='#fff'>Generate</Typography>;
                            }}
                          >
                            <MenuItem value="Regenerate Notes" onClick={() => { reGenerateConsultation();  }}>
                              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <IconButton sx={{ padding: 0, marginRight: '5px' }}>
                                  <Generate />
                                </IconButton>
                                <Typography variant="body1" sx={{ color: "#29C0BB" }}>Regenerate Notes</Typography>
                              </div>
                            </MenuItem>
                            <MenuItem value="Referral Letter" onClick={() => { setGeneratingReferral(true); getReferralLetterContent(); }}>
                              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <IconButton sx={{ padding: 0, marginRight: '5px' }}>
                                  <Generate />
                                </IconButton>
                                <Typography variant="body1" sx={{ color: "#29C0BB" }}>Referral Letter</Typography>
                              </div>
                            </MenuItem>
                            <MenuItem value="Medical Certificate" onClick={() => { setGeneratingCertificate(true); getCertificateContent(); }}>
                              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <IconButton sx={{ padding: 0, marginRight: '5px' }}>
                                  <Generate />
                                </IconButton>
                                <Typography variant="body1" sx={{ color: "#29C0BB" }}>Medical Certificate</Typography>
                              </div>
                            </MenuItem>
                          </MuiSelect>
                          {/* </FormControl> */}
                        </div>
                        </Stack>
                    </Box>
                    )}
                </Grid>
                }
                {/* {
                    (showPastConsultations &&  !firstView) &&
                      <Grid size={3}> </Grid>
                } */}
                {
                    showPastConsultations && (
                    <Grid size={!firstView ? {xs: 12, md: 5} : 12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} sx={{ marginBottom: '16px' }}>
                            <Typography variant="h6">Past Consultations</Typography>
                            <Box 
                              onClick={() => { 
                                const isAnyChecked = consultationSummaryCheckbox.some(checked => checked);
                                if (!isAnyChecked) {
                                  setAlert('Please select the notes you wish to summarise by checking the boxes in \'Summary\'', 'warning');
                                } else {
                                  setPopupSummary(true);  
                                  setConsultationSummaryList([]); 
                                  getSelectedConsultationSummary() 
                                }
                              }} 
                              sx={{ backgroundColor: '#ffffff', borderRadius: 30, display: 'flex', alignItems: 'center', padding: '10px 10px', '&:hover': { backgroundColor: '#f5f5f5'}, cursor: 'pointer'}}>
                              <IconButton sx={{padding: 0, marginRight: '5px'}}>
                                <VisibilityIcon/>
                              </IconButton>
                              <Typography noWrap variant="body1" sx={{color: "#29C0BB", fontWeight: 'bold'}}>See Summary</Typography>
                            </Box>
                            {
                                (isPopupSummary && !loading) && (
                                  <PopupMenu>
                                      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: '10px' }}>
                                        <Typography variant="h5">Patient's Consultation history</Typography>
                                        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>     
                                          <IconButton onClick={() => { 
                                            const formattedContent = consultationSummaryList.map(item => `${item.dateTime}\n${item.data}`).join('\n\n');
                                            handleCopy(`Patient's Consultation history\n\n${formattedContent}`) 
                                            }}>
                                            <CopyIcon />
                                          </IconButton>                     
                                          <Box onClick={() => { setPopupSummary(false); setConsultationSummaryList([]) }} sx={{ borderRadius: 30, backgroundColor: '#F8F9FA', padding: '5px 10px', color: "#29C0BB", cursor: 'pointer'}}>
                                            Close
                                          </Box>
                                        </Stack>
                                      </Stack>
                                      {
                                        consultationSummaryList.map((summary, index) => (
                                          summary && (
                                            <Box key={index} sx={{ backgroundColor: '#ffffff', marginBottom: '8px',  }}>
                                              <Typography variant="h6">{summary.dateTime}</Typography>
                                              <Typography variant="body1"> {summary.data}</Typography>
                                            </Box>
                                          )
                                        ))
                                      }
                                  </PopupMenu>
                                  )
                              }
                        </Stack>
                
                        <Box sx={{ backgroundColor: '#ffffff', padding: '16px', marginBottom: '16px', borderRadius: '16px 16px 0 0',}}>
                          <Grid container spacing={2} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Grid size={4} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                              <Typography noWrap variant="body2" sx={{fontWeight: 'bold'}}>Date/Time</Typography>
                            </Grid>
                            <Grid size={4} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                              <Typography noWrap variant="body2" sx={{fontWeight: 'bold'}}>Chief Complaint</Typography>
                            </Grid>
                            <Grid size={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                              <Typography noWrap variant="body2" sx={{fontWeight: 'bold'}}>Summary</Typography>
                            </Grid>
                            <Grid size={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                              <Typography noWrap variant="body2" sx={{fontWeight: 'bold'}}>SOAP</Typography>
                            </Grid>
                          </Grid>
                        </Box>
                
                        <Box sx={{ maxHeight: '60vh', overflowY: 'auto'}}>
                          {pastConsultations.map((consultation, index) => (
                            <Box onClick={() => {viewPastConsultation(index)}} key={index} sx={{ '&:hover': { border: "3px solid #29C0BB" }, backgroundColor: '#ffffff', padding: '16px', marginBottom: '8px', cursor: 'pointer' }}>
                              <Grid container spacing={2} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Grid size={4} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <Typography variant="body2">
                                  {new Date(consultation.createdAt.split('T')[0]).toLocaleDateString('en-US', {
                                    day: '2-digit',
                                    month: 'short',
                                    year: 'numeric',
                                  })}
                                </Typography>
                                <Typography variant="body2">
                                <Typography variant="body2">
                                  {consultation.createdAt.split('T')[1].split(':').slice(0, 2).join(':')}
                                </Typography>
                                </Typography>
                                </Grid>
                                <Grid size={4} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                  <Typography variant="body2">{consultation.chiefComplaint}</Typography>
                                </Grid>
                                <Grid size={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                  <Typography variant="body2">
                                  <input
                                    type="checkbox"
                                    checked={consultationSummaryCheckbox[index] || false}
                                    onClick={(e) => { e.stopPropagation(); handleCheckboxChange(index) }}
                                    onChange={(e) => {}}
                                  />
                                  </Typography>
                                </Grid>
                                <Grid size={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                  <Tooltip title="View the consultation note">
                                    <IconButton onClick={(e) => { e.stopPropagation(); handleViewConsultation(index); }} sx={{backgroundColor: '#f5f5f5', borderRadius: '8px', width: '40px', height: '40px'}} >
                                      <VisibilityIcon/>
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                        </Box>
            
                        {
                          isPopupSoap && (
                            <PopupMenu>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: '16px' }}>
                                  <Typography variant="h5">Patient's Consultation history </Typography>
                                  <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>     
                                    <IconButton onClick={() => { handleCopy(`Patient's Consultation History \n\n${selectedConsultation.chiefComplaint}\n${selectedConsultation.createdAt.split('T')[0]} ${selectedConsultation.createdAt.split('T')[1].split('.')[0]} \n\nSubjective: \n${selectedConsultationSoap.Subjective}\n\nObjective: \n${selectedConsultationSoap.Objective}\n\nAssessment: \n${selectedConsultationSoap.Assessment}\n\nPlan: \n${selectedConsultationSoap.Plan}`) }}>
                                      <CopyIcon />
                                    </IconButton>  
                                    <IconButton onClick={() => { 
                                      if (editSoap) {
                                        setEditSoap(false); 
                                        saveSoapNotes();
                                      } else {
                                        setEditSoap(true);
                                      }}}>
                                      {editSoap ? <SaveIcon/> : <EditIcon/>}
                                    </IconButton> 
                                    <IconButton onClick={() => { setOpenDialog({open: true, content: "Are you sure you want to delete this consultation?", confirmAction: removeConsultation}); }}>
                                      <DeleteIcon />
                                    </IconButton>                    
                                    <Box onClick={() => {setPopupSoap(false); setEditSoap(false);}} sx={{ borderRadius: 30, backgroundColor: '#F8F9FA', padding: '5px 10px', color: "#29C0BB", cursor: 'pointer'}}>
                                      Close
                                    </Box>
                                  </Stack>
                                </Stack>
                                <Typography variant="body2">{ `${new Date(selectedConsultation.createdAt.split('T')[0]).toLocaleDateString('en-US', {day: '2-digit', month: 'short', year: 'numeric', })} ${selectedConsultation.createdAt.split('T')[1].split(':').slice(0, 2).join(':')}` }</Typography>
                                <TextField multiline maxRows={10} label="Chief Complaint" value={selectedConsultationComplaint ?? ''}  onChange={(e) => setSelectedConsultationComplaint(e.target.value) } disabled={!editSoap} fullWidth placeholder="Chief Complaint..." sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }} />
                                <TextField multiline maxRows={10} label="Subjective" value={selectedConsultationSoap.Subjective ?? ''}  onChange={(e) => { setSelectedConsultationSoap(prevState => ({...prevState, Subjective: e.target.value}));}} disabled={!editSoap} fullWidth placeholder="Subjective..." sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }} />
                                <TextField multiline maxRows={10} label="Objective" value={selectedConsultationSoap.Objective ?? ''} onChange={(e) => { setSelectedConsultationSoap(prevState => ({...prevState, Objective: e.target.value}));}} disabled={!editSoap} fullWidth placeholder="Objective..." sx={{ marginBottom: '16px', backgroundColor: '#f5f5f5' }} />
                                <TextField multiline maxRows={10} label="Assessment" value={selectedConsultationSoap.Assessment ?? ''} onChange={(e) => { setSelectedConsultationSoap(prevState => ({...prevState, Assessment: e.target.value}));}} disabled={!editSoap} fullWidth placeholder="Assessment..." sx={{ marginBottom: '16px', backgroundColor: '#f5f5f5' }} />
                                <TextField multiline maxRows={10} label="Plan" value={selectedConsultationSoap.Plan ?? ''} onChange={(e) => { setSelectedConsultationSoap(prevState => ({...prevState, Plan: e.target.value}));}} disabled={!editSoap} fullWidth placeholder="Plan..." sx={{ backgroundColor: '#f5f5f5', marginBottom: '20px' }} />
                            </PopupMenu>
                            )
                        }
                        {
                          isPopupReferral && (
                            <PopupMenu>
                              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: '16px' }}>
                                <Typography variant="h5">Patient Referral Letter </Typography>
                                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>     
                                  <IconButton onClick={() => { handleCopy(`Patient Referral Form  \n\n\n${referralLetterContent}`) }}>
                                    <CopyIcon />
                                  </IconButton>  
                                  <IconButton onClick={() => { 
                                    if (editReferralContent) {
                                      setEditReferralContent(false); 
                                      updateReferralLetterContent(referralLetterContent);
                                    } else {
                                      setEditReferralContent(true);
                                    }}}>
                                    {editReferralContent ? <SaveIcon/> : <EditIcon/>}
                                  </IconButton>  
                                  <Box onClick={() => {setEditReferralContent(false); setGeneratingReferral(true); generateReferralLetter() }} sx={{ borderRadius: 30, backgroundColor: '#F8F9FA', padding: '5px 10px', color: "#29C0BB", cursor: 'pointer'}}>
                                    Regenerate
                                  </Box>                  
                                  <Box onClick={() => {setPopupReferral(false); setEditReferralContent(false);}} sx={{ borderRadius: 30, backgroundColor: '#F8F9FA', padding: '5px 10px', color: "#29C0BB", cursor: 'pointer'}}>
                                    Close
                                  </Box>
                                </Stack>
                              </Stack>
                              <TextField multiline maxRows={15} value={referralLetterContent} onChange={(e) => setReferralLetterContent(e.target.value)} disabled={!editReferralContent} fullWidth placeholder="No content available to display" sx={{ backgroundColor: '#f5f5f5', marginBottom: '20px' }} />
                            </PopupMenu>
                          )
                        }
                        {
                          isPopupCertificate && (
                            <PopupMenu>
                              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: '16px' }}>
                                <Typography variant="h5">Medical Certificate </Typography>
                                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>     
                                  <IconButton onClick={() => { handleCopy(`Medical Certificate  \n\n\n${certificateContent}`) }}>
                                    <CopyIcon />
                                  </IconButton>  
                                  <IconButton onClick={() => { 
                                    if (editCertificateContent) {
                                      setEditCertificateContent(false); 
                                      updateCertificateContent(certificateContent);
                                    } else {
                                      setEditCertificateContent(true);
                                    }}}>
                                    {editCertificateContent ? <SaveIcon/> : <EditIcon/>}
                                  </IconButton>  
                                  <Box onClick={() => {setEditCertificateContent(false); setGeneratingCertificate(true); generateMedicalCertificate() }} sx={{ borderRadius: 30, backgroundColor: '#F8F9FA', padding: '5px 10px', color: "#29C0BB", cursor: 'pointer'}}>
                                    Regenerate
                                  </Box>                  
                                  <Box onClick={() => {setPopupCertificate(false); setEditCertificateContent(false);}} sx={{ borderRadius: 30, backgroundColor: '#F8F9FA', padding: '5px 10px', color: "#29C0BB", cursor: 'pointer'}}>
                                    Close
                                  </Box>
                                </Stack>
                              </Stack>
                              <TextField multiline maxRows={15} value={certificateContent} onChange={(e) => setCertificateContent(e.target.value)} disabled={!editCertificateContent} fullWidth placeholder="No content available to display" sx={{ backgroundColor: '#f5f5f5', marginBottom: '20px' }} />
                            </PopupMenu>
                          )
                        }
                        {
                            (loading || savingConsultation || generatingReferral || generatingCertificate) && (
                              <PopupMenu>
                                <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
                                  <Typography variant="h6">{loading ? 'Generating Summaries' : generatingReferral ? 'Generating Referral Letter' : generatingCertificate ? 'Generating Medical Certificate' :'Saving Consultation'}</Typography>
                                  <Loader />
                                </Stack>
                              </PopupMenu>)
                        }
                        {
                          openDialog && (
                            <AlertDialog data={{open: openDialog.open, content: openDialog.content, confirmAction: openDialog.confirmAction, setOpen: setOpenDialog}}/>
                          )
                        }
                      </Grid>
                    )
                }
                </Grid>
                </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      </Container>
    );};

export default ConsultationSummary;
