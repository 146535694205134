import { configureStore, createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';

const globalStates = createSlice({
  name: 'globalStates',
  initialState: {
    open: false,
    isLoggedIn: false,
    userDetails: null,
    closeBigCalendarPopper: false,
    currentTab: 'Scribe Assistant',
  },
  reducers: {
    setCloseBigCalendarPopper: (state, action) => {
      state.closeBigCalendarPopper = action.payload
    },
    // toggleDrawer: (state) => {
    //   state.open = !state.open;
    // },
    setDrawerOpen: (state, action) => {
      state.open = action.payload;
    },
    setLoginStatus: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    }
  },
});

export const { setDrawerOpen, setLoginStatus, setUserDetails, setCloseBigCalendarPopper, setCurrentTab } = globalStates.actions;

const rootReducer = combineReducers({ globalState: globalStates.reducer });

// Create a persist configuration object
const persistConfig = {
  key: 'root', // The key to store data in localStorage
  storage, // Storage engine (defaults to localStorage)
  whitelist: ['globalState'], // State slices you want to persist
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

// Create a persistor to manage persistence
export const persistor = persistStore(store);

// const store = configureStore({
//   reducer: {
//     globalState: globalStates.reducer,
//   },
// });

export default store;
