import { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient.js';
import { useSelector, shallowEqual } from 'react-redux';

export const useMedicalScribeData = () => {
  const [patientList, setPatientList] = useState([]);
  const [consultationList, setConsultationList] = useState([]);
  const [newPatient, setNewPatient] = useState(false);
  const { userDetails } = useSelector((state) => ({userDetails: state.globalState.userDetails}), shallowEqual);

  useEffect(() => {
    if(!newPatient){
      fetchPatientData();
      fetchConsultationData();
    }
  }, [ newPatient ]);

  const fetchPatientData = async () => {
    let { data, error } = await supabase.from('medicalscribemodel').select('*').eq('userid', userDetails?.id ?? '');
    if (error){
      console.error(error);
    }
    else{
      setPatientList(data);
    }
  };

  const fetchConsultationData = async () => {
    let { data: scribeData, error: scribeError } = await supabase
      .from('medicalscribemodel')
      .select('id')
      .eq('userid', userDetails?.id ?? '');

    if (scribeError) {
      console.error('Error fetching scribe IDs:', scribeError);
    } else {
      const scribeIds = scribeData.map(item => item.id);
      if (scribeIds.length > 0) {
        let { data: consultationData, error: consultationError } = await supabase
          .from('consultationmodel')
          .select('*')
          .in('medicalScribeId', scribeIds);

        if (consultationError) {
          console.error('Error fetching consultations:', consultationError);
        } else {
          setConsultationList(consultationData);
        }
      } else {
        console.log('No scribe IDs found for the given user.');
      }
    }
  };

  const createPatient = async ( firstName, lastName, phoneNumber, complaint, remarks, userid ) => {
    let { data, error } = await supabase.from('medicalscribemodel').insert([{ name: `${lastName} ${firstName}`, firstName, lastName, phoneNumber, complaint: null, remarks: null, userid }]);
    if (error){
      console.error(error);
    }
    else {
      setNewPatient(false);
    }
  }

  const updatePatient = async ( firstName, lastName, phoneNumber, id) => {
    const { data, error } = await supabase.from('medicalscribemodel').update({ name: `${lastName} ${firstName}`, firstName, lastName, phoneNumber }).eq('id', id);
    if (error){
      console.error(error);
    }
    else {
      fetchPatientData();
    };
  }

  const deletePatient = async (id) => {
    let { data, error } = await supabase.from('medicalscribemodel').delete().eq('id', id);
    if (error){
      console.error(error);
    }
    else {
      fetchPatientData();
      fetchConsultationData();
    }
  }
  
  return {
    patientList, consultationList, newPatient, setNewPatient, createPatient, updatePatient, deletePatient, userDetails
  };
};

