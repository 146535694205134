import React, { useState } from 'react';
import { Container, IconButton, Typography, List, ListItem, Stack } from '@mui/material';
import ManageAccountProfile from './ManageAccountProfile.js';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';
import PrimarySearchAppBar from '../../components/Appbar.js';
import AlertDialog from '../../Dialog.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useLocation } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux';
import { setUserDetails, setLoginStatus } from '../../store.js';
import { useNavigate } from 'react-router-dom';
import MiniDrawer from '../../components/MiniDrawer.js';

const SettingsPage = () => {
  const [selectedOption, setSelectedOption] = useState('Manage Account and Profile');
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState({ open: false, content: '', confirmAction: null });
  const dispatch = useDispatch(); 
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(setLoginStatus(false));
    dispatch(setUserDetails(null));
    navigate('/');
  };

  return (
    <Container disableGutters maxWidth={false}>
      <Grid container sx={{overflow: 'auto', minHeight: '100vh', minWidth: '100vw'}}>
        <Grid size={{xs: 0.8, md: 0.5}}>
          <MiniDrawer />
        </Grid>
        <Grid size="grow">
          <Grid container spacing={5} sx={{ backgroundColor: '#F8F9FA', display: 'flex', flexDirection: 'column',  width: '100%', height: '100%' }}>
            <Grid size={12}>
                <PrimarySearchAppBar/>
            </Grid>
            <Grid size={12}>
                <div style={{ marginTop: theme.mixins.toolbar.minHeight, overflowX: 'auto', minHeight: '100vh', minWidth: '100vw'}}>
                <Grid container spacing={2} sx={{ padding: '0px 50px',flexDirection: { xs: "column", md: "row"}   }} justifyContent="space-between">
                    <Grid size={{xs: 12, md: 4}}>
                        <List>
                            <ListItem sx={{marginBottom: '25px'}} button>
                                <IconButton sx={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', }} component={Link} to="/consultation">
                                    <ArrowBackIcon />
                                    <Typography noWrap variant="body1" sx={{marginLeft: '20px'}}>Back to Workspace</Typography>
                                </IconButton>
                            </ListItem>
                            <ListItem button onClick={() => setSelectedOption('Manage Account and Profile')}>
                                <IconButton sx={{backgroundColor: selectedOption === 'Manage Account and Profile'  ? '#fff' : null, borderRadius: '10px', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center',}}>
                                    <AccountCircle />
                                    <Typography noWrap variant="body1" sx={{marginLeft: '20px'}}>Manage Account and Profile</Typography>
                                </IconButton>
                            </ListItem>
                            <ListItem button onClick={() => setOpenDialog({open: true, content: "Are you sure you want to logout of your account?", confirmAction: () => handleLogout()})}>
                                <IconButton sx={{backgroundColor: selectedOption === 'Logout' ? '#fff' : null, borderRadius: '10px', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                    <LogoutIcon />
                                    <Typography noWrap variant="body1" sx={{marginLeft: '20px'}}>Logout</Typography>
                                </IconButton>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid size={{xs: 12, md: 7}}>
                        {selectedOption === 'Manage Account and Profile' ? (
                            <ManageAccountProfile setOpenDialog={setOpenDialog}/>
                            ) : (
                            <Typography variant="h4">
                                {selectedOption === 'Logout' ? 'Logging out...' : `Welcome to ${selectedOption}`}
                            </Typography>
                        )}
                    </Grid>
                    {
                        <Grid size={{xs:12, md:1}}></Grid>
                    }
                    {
                    openDialog && (
                        <AlertDialog data={{open: openDialog.open, content: openDialog.content, confirmAction: openDialog.confirmAction, setOpen: setOpenDialog}}/>
                    )
                    }
                </Grid>
                </div>
            </Grid>
        </Grid>
        </Grid>
    </Grid>
    </Container>
    );
};

export default SettingsPage;
