import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setLoginStatus, setUserDetails } from '../store.js';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TikcareLogo } from '../assets/svg/TikcareLogo.svg';
import { Tooltip } from '@mui/material';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    position: 'fixed',
  }));


const useStyles = makeStyles ({
    toolbar:{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    typography:{
        textAlign: 'center',
    }
});

export default function PrimarySearchAppBar(props) {
  const classes = useStyles();
  const dispatch = useDispatch(); 
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => ({userDetails: state.globalState.userDetails}), shallowEqual);
  const [searchText, setSearchText] = useState('')

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchText(searchValue);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(setLoginStatus(false));
    dispatch(setUserDetails(null));
    navigate('/');
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton onClick={() => navigate("/settings")} size="large" aria-label="show 4 new mails">
            <SettingsIcon/>
        </IconButton>
        <p>Settings</p>
      </MenuItem>
      <MenuItem>
        <IconButton
            size="large"
            aria-label="show 17 new notifications"
        >
            <Badge badgeContent={17} color="error">
            <NotificationsIcon/>
            </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle/>
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
      <AppBar>
        <Toolbar className={classes.toolbar}>
          {/* <div style={{marginRight: '20px'}}>
            <TikcareLogo onClick={() => { navigate('/consultation'); }} style={{cursor: 'pointer'}} height={50} width={120}/>
          </div> */}
          <div style={{ display: 'flex', justifyContent:'flex-end', alignItems: 'center' }}>
            {/* <SearchBar bgcolor='#F5F7FA' placeholder="Search for something" width="20vw" value={searchText} onChange={handleSearchChange}/> */}
            <Tooltip title="Change Profile Settings">
              <IconButton onClick={() => navigate("/settings")} size="large">
                  <SettingsIcon/>
              </IconButton>
            </Tooltip>
            {/* <IconButton
              size="large"
              aria-label="show 17 new notifications"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon/>
              </Badge>
            </IconButton> */}
            <Box sx={{ flexGrow: 0.05 }} />
            <Typography
            variant="h6"
            noWrap
            component="div"
            className={classes.typography}
            >
                {userDetails ? userDetails.email : 'User'}
            </Typography>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              <AccountCircle/>
            </IconButton>
          {/* <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              <MoreIcon/>
            </IconButton>
          </Box> */}
          </div>
        </Toolbar>
        {/* {renderMobileMenu} */}
        {renderMenu}
      </AppBar>
  );
}
