import React, { useState } from 'react';
import { supabase } from '../../supabaseClient.js';
import loginImage from '../../assets/images/login.png';
import Grid from '@mui/material/Grid2';
import { Container, IconButton, Typography, MenuItem, Select, FormControl, InputLabel, Box, TextField, Stack, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TikcareLogo } from '../../assets/svg/TikcareLogo.svg';
import useAlert from '../../alert/useAlert.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const UpdatePassword = ({setOptionSelected}) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordSubmitted, setPasswordSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { setAlert } = useAlert();
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const handleResetPassword = async () => {
    if(passwordError || confirmPasswordError || !newPassword || !confirmPassword) {
      setAlert('Please enter a valid password', 'error');
      return;
    }
    if(newPassword !== confirmPassword) {
      setAlert('Passwords do not match', 'error');
      return;
    }
    const { data: { user } } = await supabase.auth.getUser()

    if(user) { 
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      })

      if(error) {
        setAlert(error.message, 'error');
      }
      else {
        setAlert("Password updated successfully!", 'success');
      }
    }
  };

  const validatePassword = (password) => {
    // Regex for at least one letter, one special character, and minimum 6 characters
    const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;
    if (!strongPasswordRegex.test(password)) {
      setPasswordError('Password must contain at least 6 characters, one letter, and one special character.');
    } else {
      setPasswordError('');
    }
  };

  const validateConfirmPassword = (password) => {
    // Regex for at least one letter, one special character, and minimum 6 characters
    const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;
    if (!strongPasswordRegex.test(password)) {
      setConfirmPasswordError('Password must contain at least 6 characters, one letter, and one special character.');
    } else {
      setConfirmPasswordError('');
    }
  };

  return (
    <div>
    <Grid container spacing={2}>
        <Grid size={12} sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
            <Stack spacing={3} width={{xs: '80vw', md: '40vw'}} direction="column" alignItems="flex-start" justifyContent="center" >
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{width: '100%'}}>
                    <Typography sx={{fontWeight: 'bold'}} noWrap variant="h6" gutterBottom>Reset Password</Typography>
                    <IconButton onClick={() => {setOptionSelected('')}}>
                        <ArrowBackIcon />
                        <Typography variant="h6" sx={{color: "#29C0BB", marginLeft: '5px'}}>Back</Typography>
                    </IconButton>
                </Stack>
            <TextField
                inputProps={{
                    autocomplete: 'new-password',
                    form: {
                    autocomplete: 'off',
                    },
                }}
                required
                fullWidth
                label="Change password"
                variant="outlined"
                focused
                value={newPassword}
                onChange={(e) => {setNewPassword(e.target.value); validatePassword(e.target.value); }}
                placeholder="Create a password" 
                type={showPassword ? "text" : "password"}
                sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }} 
                error={(passwordSubmitted && !newPassword) || passwordError}
                helperText={((passwordSubmitted && !newPassword) || passwordError) ? passwordError ?? "Enter a password" : ""}
                slotProps={{
                input: {
                    endAdornment: (
                    <InputAdornment position="end">
                    <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                    >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                    </InputAdornment>
                    ),
                },
                }}
            />
            <TextField
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                    autocomplete: 'off',
                    },
                }}
                autoComplete='off'
                required
                fullWidth
                label="Confirm password"
                variant="outlined"
                focused
                value={confirmPassword}
                onChange={(e) => { setConfirmPassword(e.target.value); validateConfirmPassword(e.target.value);} }
                placeholder="Confirm password" 
                type={showPassword ? "text" : "password"}
                sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }} 
                error={(passwordSubmitted && !confirmPassword)  || confirmPasswordError}
                helperText={((passwordSubmitted && !confirmPassword)  || confirmPasswordError) ? confirmPasswordError ?? "Enter a password" : ""}
                slotProps={{
                input: {
                    endAdornment: (
                    <InputAdornment position="end">
                    <IconButton
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        edge="end"
                    >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                    </InputAdornment>
                    ),
                }
                }}
            />
            <Button onClick={() => { setPasswordSubmitted(true); handleResetPassword();  }} variant="outlined" sx={{width: '100%', height: '50px', borderRadius: 30, backgroundColor: '#29C0BB', border: 0}}>
                <Typography variant="button" sx={{color: '#FFFFFF', fontSize: '15px'}}>Reset Password</Typography>
            </Button>
            </Stack>
        </Grid> 
      </Grid>
      </div>
  );
};

export default UpdatePassword;

